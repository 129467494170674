
const mainTag = 'autoecole'
const spesificTag = 'autoecole_moniteur'
    
const OneGConf = {
    OneLocalStorage: JSON.parse(localStorage.getItem('Garderie_Prof')), //GetForPID(),
    forPID: JSON.parse(localStorage.getItem(`${mainTag}_${spesificTag}_forPID`)),  
    themeMode: localStorage.getItem(`${mainTag}_${spesificTag}_Theme`),
    oneOffline : JSON.parse(localStorage.getItem(`${mainTag}_${spesificTag}_offline`)),
    
    themeColor: '#2f8d99',
    default_Offline : {matiereListe: [], seancesListe: [],  classesListe:[],  elevesListe:[],  seancesToSave:[]}, 
    routerName : 'C',
    routerTagName : `${mainTag}_${spesificTag}`,

    topBarName : 'T_Name',

    loginData : {
        tableToLgIn : '02_garderie_team',
        Identifiant : 'Identifiant',
        Password : 'Password',
        LogId : 'Now_Login_ID',
        ID_Tag : 'T_ID'
    },

    main : [
        {id:1, link:'ns', icon:'hourglass-split', text:'NOUVEAUX SEANCE', desc:''},
        {id:3, link:'jr', icon:'journal-bookmark-fill', text:'MES SEANCES', desc:''},
        {id:2, link:'ap', icon:'people', text:'APPELLE', desc:''},
        {id:4, link:'mp', icon:'calendar-week', text:'EMPLOI', desc:''},
        {id:5, link:'ex', icon:'bag-check-fill', text:'EXAMAIN', desc:''},
         
    ],

 

    //return back card 
    backCard:{
        nv : {id:1, text:'Nouveaux Facture', link:'/C/L'},
        pann : {id:1, text:'Nouveaux Facture', link:'/C/L/sk'},

        mf : {id:7, text:'Mes Factures', link:'/C/L/rt'},
        mfInfo : {id:8, text:'Facture Info', link:'/C/L/rt/vente'},
        mfEdit : {id:8, text:'Modifier Facture', link:'/C/L/rt/vente'},
       
        sk : {id:9, text:'Stock', link:'/C/L'},
        skList : {id:10, text:'Mon Stock', link:'/C/L/sk'},
        skInfo : {id:12, text:'Info Sur Article', link:'/C/L/sk'},
        skfamilleList: {id:13, text:'Mon Stock', link:'/C/L/sk/Famille'},
        skFond: {id:13, text:'Fonds', link:'/C/L/sk'},
        skFondD: {id:14, text:'Fonds Info', link:'/C/L/sk'},

        vt : {id:1, text:'Mes Ventes', link:'/C/L'},
        vtRech : {id:1, text:'Recherche', link:'/C/L/vt'},
        vtJour : {id:1, text:'Aujourd\'hui', link:'/C/L/vt'},

        cl : {id:2, text:'Client', link:'/C/L'},
        clAdd : {id:3, text:'Ajouter Client', link:'/C/L/cl'},
        clPtg : {id:4, text:'Pointage des Clients', link:'/C/L/cl'},
        clMap : {id:5, text:'Recherche des Clients', link:'/C/L/cl'},
        clList : {id:6, text:'Liste des Clients', link:'/C/L'},

        rt : {id:1, text:'Recette', link:'/C/L'},
        rtDeps : {id:1, text:'Depenses', link:'/C/L/rt'},
        rtImpr : {id:1, text:'Imprimer', link:'/C/L/rt'},

        up : {id:1, text:'Mettre a jour ', link:'/C/L'},

    },
    offlineData : [
        {genreName : 'elevesListe', textName:'Liste des Eleves', tableName:'02_garderie_eleves', offLineValue:'elevesListe'},
        {genreName : 'classesListe', textName:'Liste des Classes',  tableName:'02_garderie_classes', offLineValue:'classesListe'},
        {genreName : 'seancesListe', textName:'Liste des Seances',  tableName:'02_garderie_seances', offLineValue:'seancesListe'},
        {genreName : 'matiereListe', textName:'Liste des Matiére',  tableName:'02_garderie_seances_matiere', offLineValue:'matiereListe'},
    ]
}
export default OneGConf