import React, {useEffect,useState}  from 'react';
import GConf from '../../../AssetsM/generalConf';
import SubNav from '../../../AssetsM/Cards/subNav';
import { _ } from "gridjs-react";
import axios from 'axios';
import { Fade } from 'react-reveal';
import Ripples from 'react-ripples'
import SKLT from '../../../AssetsM/Cards/usedSlk';
import TableGrid from '../../../AssetsM/Cards/tableGrid';
import TableImage from '../../../AssetsM/Cards/tableImg';
import GoBtn from '../../../AssetsM/Cards/goBtn';
import { toast } from 'react-toastify';
import { Button , Icon, Modal} from 'semantic-ui-react';
import { useNavigate} from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import BackCard from '../Assets/Cards/backCard';
import OneGConf from '../Assets/OneGConf';


function FacturePage() {
    /*#########################[Const]##################################*/
    const navigate = useNavigate();
    const [facturesList, setFactureList] = useState([SKLT.TableSlt]); 
    let Offline = JSON.parse(localStorage.getItem(`${OneGConf.forPID.PID}_Offline`));
    const [modalS, setModalS] = useState(false)
    const [selectedArticle, setSelectedArticle] = useState([])
    const [eleveListe, setEleveListe] = useState([])
     /*#########################[UseEffect]##################################*/
     useEffect(() => {
        axios.post(`${GConf.ApiLink}/seances`, {
            PID : OneGConf.forPID.PID,
        })
        .then(function (response) {
            
            let factureListContainer = []
            response.data.map( (getData) => factureListContainer.push([
            _(<TableImage image='seance.png' />),
             
            getData.CL_Name,
            getData.SE_Time_Start,
            getData.SE_Time_Finish,
            
            _(<Button className='rounded-pill bg-system-btn' size='mini' onClick={ (e) => ShowEleveListe(getData.CL_ID)}> <span className='bi bi-megaphone'></span></Button>)
            ],))
            setFactureList(factureListContainer)
        }).catch((error) => {
            if(error.request) {
              toast.error(<><div><h5>Probleme de Connextion</h5> Les Donnée importeé sont les ancien donneé</div></>, GConf.TostInternetGonf) 
               
            }
          });
    }, [])
    
    
    /*#########################[Function]##################################*/
    const NavigateFunction = (link) => {  navigate(link) }
    const openEditModal = (event,selected) =>{
        setSelectedArticle(event)
        setModalS(true)
    }
    const ShowEleveListe = (value) => {
        axios.post(`${GConf.ApiLink}/seances/classes/eleves`, {
            PID : OneGConf.forPID.PID,
            classeID : value
        })
        .then(function (response) {
               setEleveListe(response.data)
               
        }).catch((error) => {
            if(error.request) {
              toast.error(<><div><h5>Probleme de Connextion</h5> Impossible de charger les article de camion  </div></>, GConf.TostInternetGonf)   
            }
          });
    }

    const UpdatePresenceState = (value,state) =>{
        const searchIndex = eleveListe.findIndex((article) => article.EL_ID === value);
        if (searchIndex !== -1) {
            const updatedEleveListe = [...eleveListe]; // Copy the eleveListe array
            updatedEleveListe[searchIndex] = { ...updatedEleveListe[searchIndex], presenceState: state };
            setEleveListe(updatedEleveListe);
        }
    }

    const PresenceListeCard = (props) =>{
        const StateCard = ({ status }) => {
            const statusCard = React.useCallback(() => {
              switch(status) {
                case 'P': return <span className='bi bi-check-circle-fill text-success'></span>;  
                case 'A': return <span className='bi bi-x-circle-fill text-danger'></span> ;
                default:  return '';    
              }
            }, [status]);
          
            return (
              <span>
                {statusCard()}
              </span>
            );
        };
        
        return(<>
                    <Ripples className='d-block p-0 mb-1 rounded-pill' >   
                    <div className='card shadow-sm p-2  rounded-pill ps-4' style={{backgroundColor : props.dataA.presenceState ? '#f5e4e4' : ''}}>
                        <div className='row'>
                            <div className='col-1 align-self-center'><b>{props.index +1 }</b></div>
                            <div className='col-4 text-start align-self-center'>
                                <div>{props.dataA.EL_ID}</div> 
                            </div>
                            <div className='col-5 align-self-center'><b><StateCard status={props.dataA.presenceState} /> {props.dataA.EL_Name}    </b></div>
                            <div className='col-1 align-self-center'><Button icon="times" className='rounded-circle p-2 text-white bg-danger'  onClick={() => UpdatePresenceState(props.dataA.EL_ID,'A')}></Button></div>
                            <div className='col-1 align-self-center'><Button icon="check" className='rounded-circle p-2 text-white bg-success'  onClick={() => UpdatePresenceState(props.dataA.EL_ID,'P')}></Button></div>
                        </div>
                    </div>
                    </Ripples>
                </>)
    }
    const PresenceCard = () =>{
        return(<>
                <h5>Listes des Eleves</h5>    
                {eleveListe.map( (val,index) => <PresenceListeCard key={index} index={index} dataA={val}/>)}
                <br />
            </>)
    }
    const SavePresence = () => {

    }
    /*#########################[Card]##################################*/
    const SDF = (props)=>{
      return(<>
         <div className='text-center'><span className={`bi bi-${props.state == "true" ? 'check-circle-fill text-success': 'x-circle-fill text-danger'}`}></span> </div>
      </>)
    }
    
    const MainSubNavCard = (props) =>{
        return(<>
           <NavLink exact='true' to={`/S/${props.link}`} className='card card-body mb-1 rounded-pill shadow-sm d-inline-block ' >
            <h4 style={{color : GConf.themeColor}}> <span className={`bi bi-${props.icon} me-1 `}></span>{props.text}</h4>
          </NavLink>
        </>) 
    }

    return (<>

        <div className={`${OneGConf.themeMode == 'dark' ? 'bg-dark-theme-2' : '' }`} style={{height: '100vh', overflow: 'scroll'}}>
              <BackCard data={OneGConf.backCard.rt}/>
              <br />
              <div className='container'>
                  <div className='row'>
                     <div className='col-5'><TableGrid tableData={facturesList} columns={['*','Classe','De','Vers','App']} /></div>
                     <div className='col-7'>
                          <PresenceCard />
                          {eleveListe.length == 0 ? <></> : 
                          <div className='col-12'>
                          <Button  className='rounded-pill bg-system-btn'   fluid onClick={() => SavePresence()}><Icon name='save' /> Enregistrer  </Button>
                      </div>
                      }
                     </div>
                  </div>
              </div>
          </div>

          

    </>);
}

export default FacturePage;